<template>
  <ThePopup title="RECURRING DONATIONS">
    <div class="col-12 above white_bg">
      <div class="row mx-0 justify-content-center">
        <div class="col-12 col-md-8 mb-2">
          <div class="row mx-0 mt-4 justify-content-center">
            <div class="col-12 p-0">
              <div class="row mx-0 justify-content-center">
                <div class="col-12 mb-3 text-center medium">
                  You have already added donations to your cart, would you like to setup a recurring payment with these selected appeals, or start a new quick recurring donation?
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 p-0 align-self-end above">
      <div class="row m-0 background-green py-3 justify-content-center">
        <!-- !PAGE -->
        <div class="col-md-10">
          <div class="row">
            <div class="col-12 mb-2">
              <Button color="green_light" width="100" btnText="Continue with Donations in my Cart" icon="arrow" @buttonClicked="useCart" class="d-none d-md-block">
                <IconArrowForward color="white" />
              </Button>
              <Button color="green_light" width="100" btnText="Continue with my Cart" icon="arrow" @buttonClicked="useCart" class="d-md-none">
                <IconArrowForward color="white" />
              </Button>
            </div>
            <div class="col-12 mb-2">
              <Button color="green_light" width="100" btnText="Start New Quick Recurring Donation" icon="arrow" @buttonClicked="startNew" class="d-none d-md-block">
                <IconArrowForward color="white" />
              </Button>
              <Button color="green_light" width="100" btnText="Start New Donation" icon="arrow" @buttonClicked="startNew" class="d-md-none">
                <IconArrowForward color="white" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ThePopup>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    ThePopup: defineAsyncComponent(() => import('../components/ThePopup.vue')),
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    IconArrowForward: defineAsyncComponent(() => import('@/components/icons/IconArrowForward.vue'))
  },
  name: 'Use Cart option',
  methods: {
    useCart () {
      this.$emit('useCart')
    },
    startNew () {
      this.$emit('startNew')
    }
  }
}

</script>

<style scoped>
.above {
  z-index: inherit;
}
.background-green {
  border-bottom-left-radius: 13px;
  border-bottom-right-radius: 13px;
}

</style>
